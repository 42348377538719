import React, { useState, useEffect } from "react";
import { Avatar, Table } from "keep-react";
import DataPicker from "../../component/DataPicker";
import {
  TablePagination
} from "@mui/material";
import Img_bancolombia from "../../assets/img/bancolombia.png";
import Img_pse from "../../assets/img/pse-logo.png";
import Img_card from "../../assets/img/Tarjetas1.png";
import { formatCurrency, formatDateTime } from "../../data/utilidades";
// import "../../assets/css/Table.css";
import "../../assets/css/Desembolso.css";
import ModalTran from "./ModalTran";

const TableTransacion = ({ data,filterTblTransaccion, update, download,date}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(null);

  const handleOpen = (row) => {
    setIndex(row);
    setIsOpen(true);
  };

  const getColorByStatus = (status) => {
    switch (status) {
      case "APPROVED":
        return "bg-green-400 p-0";
      case "PENDING":
        return "bg-yellow-400 p-2";
      case "DECLINED":
        return "bg-red-400 p-2";
      default:
        return "bg-gray-200";
    }
  };
  const ImgMetodoPago = (metoPago) => {
    switch (metoPago) {
      case "BANCOLOMBIA_TRANSFER":
        return Img_bancolombia;
      case "PSE":
        return Img_pse;
      case "CARD":
        return Img_card;
    }
  };

  // const useWindowSize = () => {
  //   const [width, setWidth] = useState(window.innerWidth);
  //   useEffect(() => {
  //     const handleResize = () => setWidth(window.innerWidth);
  //     window.addEventListener("resize", handleResize);
  //     return () => window.removeEventListener("resize", handleResize);
  //   }, []);
  //   return width;
  // };

  const [page, setPage] = useState(0);
  const rowsPerPage = 6;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  let slicedData = [];
  // if (Array.isArray(data)) {
    if(data.length > 0){
    // Usar slice en data
    slicedData = data.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  } 
  // else {
  //   console.error("Data no es un array:", data);
  // }
  useEffect(() => {
    setPage(0);
  }, [data]);
  return (
    <div className="overflow-x-auto">
      {/* {useWindowSize() > 768 ? <LargeTable /> : <SmallTable />} */}
      <Table className="table" showCheckbox={false}>
        <Table.Caption>
        <DataPicker date={date} filterTblTransaccion={filterTblTransaccion} update={update} download={download}/>
        </Table.Caption>
        <Table.Head className="thead">
          <Table.HeadCell className="min-w-[150px]">
            <p className="text-body-5 font-medium text-metal-400">Estado</p>
          </Table.HeadCell>
          <Table.HeadCell className="min-w-[140px]">Tipo de pago</Table.HeadCell>
          <Table.HeadCell className="min-w-[151px]">Monto y cliente</Table.HeadCell>
          <Table.HeadCell className="min-w-[151px]">Datos del pago</Table.HeadCell>
          <Table.HeadCell className="min-w-[80px]">Hora y fecha</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-gray-25 divide-y">
          {slicedData.map((row, index) => (
            <Table.Row  className="bg-white cursor-pointer" key={index} onClick={() => handleOpen(row)}>
              <Table.Cell className="p-2">
                <span
                    className={`uppercase text-xs md:text-sm text-center text-white inline-block px-2 py-1 border rounded-full ${getColorByStatus(
                      row.status
                    )}`}
                  >
                    {row.status ? row.status : "-"}
                  </span>
              </Table.Cell>
              <Table.Cell className="p-2">
                <div className="flex items-center gap-3">
                  <Avatar shape="circle" img={ImgMetodoPago(row.payment_method.type)} size="md" />
                </div>
              </Table.Cell>
              <Table.Cell className="p-2">
                <p className="text-body-5 font-medium text-metal-500">
                {row.currency && row.amount_in_cents
                    ? row.currency + " " + formatCurrency(row.amount_in_cents)
                    : "-"}{" "}
                      <br />
                  {row.customer_email || "-"}
                </p>
              </Table.Cell>
              <Table.Cell className="p-2">
                <p className="text-body-5 font-medium text-metal-500">
                {row.reference ? row.reference : "-"}
                </p>
              </Table.Cell>
              <Table.Cell className="p-2">
              <p className="text-body-5 font-medium text-metal-500">
              {row.created_at ? formatDateTime(row.created_at) : "-"}
              </p>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
        <ModalTran
          data={index}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
          <div className="flex justify-end">
              <TablePagination  
                 rowsPerPageOptions={[6]}
                 count={data.length}
                 rowsPerPage={rowsPerPage}
                 page={page}
                 onPageChange={handleChangePage}
               />
                 </div>
    </div>
  );
};

export default TableTransacion;
