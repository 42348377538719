import React from "react";

import { ContextProvider } from "../context/ContextProvider";

const Button = ({icon,bgColor,color,bgHoverColor,size,text,borderRadius,width,control,onClick}) => {
 

  // function logOut() {
  //  console.log("logOut");
  // }
  return (
    <ContextProvider>
      <button
        type="button"
        onClick={onClick}
        style={{ backgroundColor: bgColor, color, borderRadius }}
        className={` text-${size} p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
      >
        {icon} {text}
      </button>
    </ContextProvider>
  );
};

export default Button;
