import React, { useEffect, useState } from "react";
import "../assets/css/SkeletoTable.css";
// import DataPicker from "../component/DataPicker";
import TableTransacion from "../component/transacciones_c/TableTransacion";
import Spinner from "../component/Spinner";
import * as XLSX from 'xlsx';
import { reorganizarfile } from "../data/utilidades";
const TableComponent = () => {
  const [data, setData] = useState([]);
  // const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const idcliente = localStorage.getItem("Empresa");
  const [error, setError] = useState(null);
  const [date, setDate] = useState({});
  const fetchData = (page) => {
    setIsLoading(true);
    setError(null);
    fetch(
      `${REACT_APP_API_URL}/gettransactionMA?idcliente=${idcliente}&page=${page}&limit=${itemsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // console.log("data 2", data.data);
        if (data && data.data && data.data.length > 0) {
          setData(data.data);
          // setTotal(data.total);
        } else {
          setError("No data available");
        }
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData(currentPage);
  }, []);
 
  const update=()=>{
    fetchData(currentPage); 
  }
  const filterTblTransaccion = (obj) => { 
    //  console.log("obj", obj);
     setIsLoading(true);
    fetch(
      `${REACT_APP_API_URL}/Transaccion/FiltroTblTransaccion`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj)
      }
    ).then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      }).then((data) => {
        if (data) {
          //  console.log("data", data.data);
           setData(data.data);
        } else {
          setError("No data available");
        }
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });  
  };
  const download = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hour = now.getHours();
    const minute = now.getMinutes();
    
    const monthString = month.toString().padStart(2, '0');
    const dayString = day.toString().padStart(2, '0');
    const hourString = hour.toString().padStart(2, '0');
    const minuteString = minute.toString().padStart(2, '0');
    
    const dateTimeString = `${year}-${monthString}-${dayString}${hourString}:${minuteString}`;

    const newdata=reorganizarfile(data);
    const fileName='ipaidtran'+dateTimeString;
    const workbook = XLSX.utils.book_new();
  
    const worksheet = XLSX.utils.json_to_sheet(newdata);
  
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };
  return (
    <div className=" pr-2 pl-2 bg-white rounded shadow-md w-12/12 mx-auto">
      {error && <div className="error-message">{error}</div>}
      <div className="App">
        {/* <div>
        <DataPicker date={date} filterTblTransaccion={filterTblTransaccion} update={update} download={download}/>
        </div> */}
        {/* {isLoading ? 
        ( 
          <div>
            <table className="min-w-full table-auto text-gray-600 text-sm">
              <thead>
                <tr className="text-left">
                <th className="p-2">Estado</th>
                  <th className="p-2">Tipo de pago</th>
                  <th className="p-2">Monto y cliente</th>
                  <th className="p-2">Datos del pago</th>
                  <th className="p-2">Hora y fecha</th>
                  <th className="p-2"></th>
                </tr>
              </thead>
              <tbody>
                {Array(6)
                  .fill(null)
                  .map((_, index) => (
                    <tr
                      key={index}
                      className="border-t cursor-pointer hover:bg-gray-100"
                    >
                      <td className="p-2">
                        <span className="skeleton block w-3/4 h-5 rounded"></span>
                      </td>
                      <td className="p-0">
                        <span className="skeleton_img block px-0 py-1 w-6 h-6 rounded-full"></span>
                      </td>
                      <td className="p-0 ml-[-50px]">
                        <span className="skeleton block w-3/4 h-5 rounded"></span>
                      </td>
                      <td className="p-0">
                        <span className="skeleton block w-3/4 h-5 rounded"></span>
                      </td>
                      <td className="p-0 text-center">
                        <span className="skeleton block w-3/4 h-5 rounded"></span>
                      </td>
                      <td className="p-0"></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
         
     <TableTransacion data={data} date={date} filterTblTransaccion={filterTblTransaccion} update={update} download={download} />
         
        )} */}
        <div className="spinn">
        {isLoading && <Spinner color="gray" size="lg" />}
          </div>
        <TableTransacion data={data} date={date} filterTblTransaccion={filterTblTransaccion} update={update} download={download} />
    </div>
    </div>
  );
};

export default TableComponent;
