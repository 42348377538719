import React from "react";
import { formatDateTime,formatCurrency } from "../../data/utilidades";

const Modal = ({ isOpen, onClose, data }) => {
  // console.log("data", data);
  if (!isOpen) {
    return null;
  }
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl modal">
            {/* modal */}

            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Resumen
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Detalle de la transferencia
                </p>
              </div>
              <div className="border-t border-b border-gray-200">
                <dl>
                  <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Valor Transferencia:
                    </dt>
                    <dd className="mt-1 ml-auto text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.amount_in_cents && data.currency
                        ? data.currency +
                          " " +
                          formatCurrency(data.amount_in_cents)
                        : "-"}
                    </dd>
                  </div>
                </dl>
                <dl></dl>
                <dl>
                  <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Email:
                    </dt>
                    <dd className="mt-1 ml-auto text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.customer_email ? data.customer_email : "-"}
                    </dd>
                  </div>
                </dl>
                <dl>
                  <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Metodo de Pago:
                    </dt>
                    <dd className="mt-1 ml-auto text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.payment_method_type
                        ? data.payment_method_type
                        : "-"}
                    </dd>
                  </div>
                </dl>
                <dl>
                  <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Referencia:
                    </dt>
                    <dd className="mt-1 ml-auto text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.reference ? data.reference : "-"}
                    </dd>
                  </div>
                </dl>
                <dl>
                  <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Id transaccion:
                    </dt>
                    <dd className="mt-1 ml-auto text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.id ? data.id : "-"}
                    </dd>
                  </div>
                </dl>
                <dl>
                  <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Fecha y hora:
                    </dt>
                    <dd className="mt-1 ml-auto text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.finalized_at
                        ? formatDateTime(data.finalized_at)
                        : "-"}
                    </dd>
                  </div>
                </dl>
                <dl>
                  <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Estado de la Transferencia:
                    </dt>
                    <dd className="mt-1 ml-auto text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.status ? data.status : "-"}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                onClick={onClose}
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cerrar
              </button>
              {/* <button onClick={handleCancelClick}  type="button" className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Cancel</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
