import React, { useEffect, useState } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import QRCode from "react-qr-code";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../assets/css/GenereteQR.css";
import Loading from "../component/Loading";

const MySwal = withReactContent(Swal);

const Generate = () => {
  const [loading, setLoading] = React.useState(true);
  const [formValues, setFormValues] = useState({
    nombre: '',
    correo: '',
    precio: '',
    celular: '',
    referencia: '',
    nombreProducto: '',
    descripcion: '',
    formaDePago: ''
});

  // const [qrValue, setQrValue] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [GenerateQr, setGenerateQr] = useState("");
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const REACT_APP_WEBPAGE = process.env.REACT_APP_WEBPAGE;

  const empresa = localStorage.getItem("Empresa");
  const [data, setData] = useState([]);
  const [datacon, setDatacon] = useState([]);
  const qrCodeStyle = {
    marginTop: "10px",
    padding: "10px",
    backgroundColor: "#fff",
    display: "inline-block",
    borderRadius: "8px", // Si quieres bordes redondeados en el contenedor
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Un ligero sombreado para resaltar el QR del fondo
  };

  useEffect(() => {
    setLoading(false);
    setDatacon(JSON.parse(localStorage.getItem("config")));
  }, []);
  // Esta función manejaría el cambio en los campos del formulario
  const validateForm = () => {
    let newErrors = {};  // Objeto para almacenar los nuevos errores
    let formIsValid = true;

    // Validación del correo
    if (!formValues.correo || !formValues.correo.trim()) {
        newErrors.correo = "El correo es requerido";
        formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formValues.correo)) {
        newErrors.correo = "El correo no es válido";
        formIsValid = false;
    }
    if (!formValues.celular) {
      newErrors.celular = "El número de celular es requerido";
      formIsValid = false;
  } else if (formValues.celular.length < 10 || formValues.celular.length > 10) { // Asumiendo que esperas 10 dígitos
      newErrors.celular = "El número de celular debe tener 10 dígitos";
      formIsValid = false;
  }

    // Validación de referencia
    if (!formValues.referencia || !formValues.referencia.trim()) {
        newErrors.referencia = "La referencia es requerida";
        formIsValid = false;
    }

    // Actualiza el estado de errores solo con los nuevos errores encontrados
    setErrors(newErrors);
    return formIsValid;
};

const handlePrecioChange = (e) => {
  const value = e.target.value.replace(/\D/g, ""); // Eliminar caracteres no numéricos

  // Formatear el valor a un formato de moneda (opcional, según prefieras)
  // const formattedValue = value ? Intl.NumberFormat("es-CO").format(Number(value)) : "";

  setFormValues(prevValues => ({
      ...prevValues,
      precio: value //formattedValue
  }));

  // Limpiar error cuando el usuario cambia el valor
  if (errors.precio) {
      setErrors(prevErrors => ({
          ...prevErrors,
          precio: ""
      }));
  }
};

  const handleCelularChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Esto elimina todo lo que no sean dígitos
    setFormValues(prevValues => ({
        ...prevValues,
        celular: value
    }));

    // Limpiar error cuando el usuario cambia el valor
    if (errors.celular) {
        setErrors(prevErrors => ({
            ...prevErrors,
            celular: ""
        }));
    }
};
const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormValues(prevValues => ({
      ...prevValues,
      [name]: value
  }));

  // Limpiar error específico cuando se empieza a escribir en el campo
  if (errors[name]) {
      setErrors(prevErrors => ({
          ...prevErrors,
          [name]: ""
      }));
  }
};

  // Esta función manejaría la generación del QR
  const handleGenerateQR = () => {
    if (validateForm()) {
      const { correo, nombre, precio, referencia, formaDePago, descripcion, celular,nombreProducto } = formValues;
      const now = new Date();
      let referenciac =referencia+"_"+now.getDay() + now.getMinutes() + now.getSeconds()+"_"+empresa;
      const url = `${REACT_APP_WEBPAGE}idc=${empresa}&reference=${referenciac.replace(/\s/g, "")}&price=${precio}&nombre=${nombreProducto}&desc=${descripcion}&sm=0&op=${formaDePago}&nc=${nombre}&te=${celular}&co=${correo}`;
      // const url = `${REACT_APP_WEBPAGE}idc=${empresa}&reference=${referenciac.replace(/\s/g, "")}&price=${precio}&nombre=${nombreProducto}&desc=${descripcion}&sm=0&op=${formaDePago}&nc=${nombre}&te=${''}&co=${''}`;
      // const url = `${REACT_APP_WEBPAGE}idc=${empresa}&reference=${referenciac.replace(/\s/g, "")}`;
      const urlencode=encodeParametersToUrl(url);
      // console.log(urlencode); // Para debugging
      setGenerateQr(urlencode);
      setIsFormSubmitted(true);
      return url;  // Devolver URL para ser usada en handleSend si es necesario
    } else {
      console.log("El formulario tiene errores");
      return null;  // Devolver null si hay errores
    }
  };

  function encodeParametersToUrl (url) {
      // Extraemos los parámetros de la URL
      const params = new URL(url).search;
      // Codificamos los parámetros en base64
      const encodedParams = btoa(params);
      // Reconstruimos la URL con el código base64 en lugar de los parámetros
      const baseUrl = new URL(url).origin + new URL(url).pathname;
      return `${baseUrl}?${encodedParams}`;
     };
  // Esta función manejaría el envío del formulario
  const handleSend = (method) => {
    let urlToSend = GenerateQr || handleGenerateQR();
    if (!urlToSend) return;
  
    let baseApiUrl = `${REACT_APP_API_URL}/send-email/`;
    // console.log("baseApiUrl", baseApiUrl);
  
    if (method === "correo") {
      let encodedEmail = encodeURIComponent(formValues.correo);
      let encodedQR = encodeURIComponent(urlToSend);
  
      let urlWithParams = `${baseApiUrl}?email=${encodedEmail}&url=${encodedQR}&ref=${formValues.referencia.replace(/\s/g, "")}&empresa=${datacon.nombre}`;
      // console.log(urlWithParams);  // Para debugging
  
      fetch(urlWithParams, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log("Correo enviado con éxito", data);
        setData(data);
        MySwal.fire("Enviado!", "Correo enviado.", "success");
      })
      .catch(error => {
        console.log("Error enviando correo", error);
        setErrors({ global: "Error enviando correo" });
      });
    } else if (method === "Whastapp") {
      let url = `https://wa.me/${formValues.celular}?text=${encodeURIComponent(urlToSend)}`;
      window.open(url, "_blank");
    }
  };
  
  const mostrarModalConfirmacion = (op) => {
    switch (op) {
      case 1:
        MySwal.fire({
          title: "Advertencia!",
          text: "Desea enviar el QR por Correo?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí, enviar!",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            // Aquí puedes manejar lo que sucede cuando el usuario confirma
            handleSend("correo");
            MySwal.fire("Enviado!", "Correo enviado.", "success");
          }
        });
        break;
      case 2:
        MySwal.fire({
          title: "Advertencia!",
          text: "Desar continuar con el envío por WhatsApp?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí, enviar!",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            // Aquí puedes manejar lo que sucede cuando el usuario confirma
            handleSend("Whastapp");
            MySwal.fire("Enviado!", "Revisa tu Chat.", "success");
          }
        });
    }
  };
 

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col md:flex-row w-full h-full">
          {/* Formulario */}
          <div className="w-full md:w-10/10 p-5">
            <form>
              {/* Aquí tus inputs */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="mb-4">
                  <label
                    htmlFor="nombre"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Nombre Completo
                  </label>
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    value={formValues.nombre || ''}  // Asegúrate de que todos los inputs tengan esto
                    onChange={handleInputChange}
                    placeholder="Juan Pérez"
                    className="border-2 border-gray-300 p-2 w-full rounded-md"
                    />

                  {errors.nombre && (
                    <p className="text-red-500">{errors.nombre}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="correo"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Correo Electrónico
                  </label>
                  <input 
                    type="email"
                    id="correo"
                    name="correo"
                    value={formValues.correo || ''}
                    onChange={handleInputChange}
                    placeholder="juan.perez@example.com"
                    className="border-2 border-gray-300 p-2 w-full rounded-md"
                  />
                  {errors.correo && (
                    <p className="text-red-500">{errors.correo}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="precio"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Precio
                  </label>
                  <input
                    type="text" // Cambiado a texto para poder ver la formatación
                    id="precio"
                    name="precio"
                    value={formValues.precio || ''}

                    onChange={handlePrecioChange}
                    placeholder="100000"
                    className="border-2 border-gray-300 p-2 w-full rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="celular"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Número de Celular
                  </label>
                    <input
                    type="tel"
                    id="celular"
                    name="celular"
                    value={formValues.celular || ''}
                    onChange={handleCelularChange}
                    placeholder="+57 300 123 4567"
                    className="border-2 border-gray-300 p-2 w-full rounded-md"
                    />
                  {errors.celular && (
                    <p className="text-red-500">{errors.celular}</p>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="referencia"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Referencia del Producto
                  </label>
                  <input
                    type="text"
                    id="referencia"
                    name="referencia"
                    value={formValues.referencia || ''}
                    onChange={handleInputChange}
                    placeholder="REF12345"
                    className="border-2 border-gray-300 p-2 w-full rounded-md"
                    maxLength={20}
                  />
                  {errors.referencia && (
                    <p className="text-red-500">{errors.referencia}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="formaPago"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Forma de Pago
                  </label>
                      <select
                    id="formaDePago"
                    name="formaDePago"

                    onChange={handleInputChange}
                    className="border-2 border-gray-300 p-2 w-full rounded-md"
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="bancolombia">Bancolombia</option>  
                      <option value="PSE">PSE</option>
                      {/* <option value="">Seleccione una opción</option>
                      <option value="bancolombia">Bancolombia</option>
                      <option value="PSE">PSE</option> */}
                    </select>
                  {errors.formaDePago && (
                    <p className="text-red-500">{errors.formaDePago}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="nombreProducto"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Nombre del Producto
                  </label>
                  <input
                    type="text"
                    id="nombreProducto"
                    name="nombreProducto"
                    value={formValues.nombreProducto || ''}
                    onChange={handleInputChange}
                    placeholder="Camiseta Algodón"
                    className="border-2 border-gray-300 p-2 w-full rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="descripcion"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Descripción
                  </label>
                  <textarea
                    id="descripcion"
                    name="descripcion"
                    value={formValues.descripcion || ''}
                    onChange={handleInputChange}
                    className="border-2 border-gray-300 p-2 w-full rounded-md"
                    rows="3"
                    style={{height:'44px'}}
                ></textarea>
                </div>
              </div>

              {/* Agrega más inputs según necesites */}

              {/* Botón para generar QR, se muestra solo si el formulario no ha sido enviado */}
              <button
                type="button"
                onClick={handleGenerateQR}
                className="mt-4 bg-blue-500 text-white p-3 rounded"
              >
                Generar
              </button>
            </form>
          </div>

          {/* Sección QR y botones */}
          <div className="w-full md:w-3/10 p-5 flex flex-col items-center">
            {/* Aquí se muestra el QR si se ha generado */}
            {isFormSubmitted && (
              <>
                <div style={qrCodeStyle}>
                  <QRCode
                    value={GenerateQr}
                    size={256} // tamaño del QR
                    // bgColor y fgColor para el alto contraste blanco y negro
                    bgColor={"#ffffff"} // fondo blanco
                    fgColor={"#000000"} // elementos en negro
                    level="H" // Nivel de corrección de errores alto (30%)
                    // No incluye margin prop, por defecto viene con un margen estándar
                  />
                </div>
                <div className="mt-4 flex justify-center items-center">
                  <button
                    type="button"
                    onClick={() => mostrarModalConfirmacion(1)}
                    className="bg-blue-600 text-white p-3 rounded flex items-center justify-center m-2 hover:bg-blue-700 hover:shadow-lg transition duration-300"
                  >
                    <MdEmail className="mr-2" />
                    Enviar por correo
                  </button>
                  <button
                    type="button"
                    onClick={() => mostrarModalConfirmacion(2)}
                    className="bg-green-500 text-white p-3 rounded flex items-center justify-center m-2 hover:bg-green-600 hover:shadow-lg transition duration-300"
                  >
                    <IoLogoWhatsapp className="mr-2" />
                    Enviar por WhatsApp
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Generate;
