export function formatDateTime(dateTime) {
  const date = new Date(dateTime);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11, así que agregamos 1
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
// export function formatDateTime(dateTime) {
//   const date = new Date(dateTime);

//   const year = date.getUTCFullYear();
//   const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11, así que agregamos 1
//   const day = String(date.getUTCDate()).padStart(2, "0");
//   const hours = String(date.getUTCHours()).padStart(2, "0");
//   const minutes = String(date.getUTCMinutes()).padStart(2, "0");

//   return `${year}-${month}-${day} ${hours}:${minutes}`;
// }
// export function toLocalISOString(date) {
//   const offset = date.getTimezoneOffset();
//   const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
//   return adjustedDate.toISOString().slice(0, -1);
// }
export function toLocalISOString(input) {
  // Intentar convertir la entrada a un objeto Date si aún no lo es
  const date = input instanceof Date ? input : new Date(input);

  // Comprobar si el objeto Date es válido
  if (isNaN(date.getTime())) {
    throw new Error("Invalid date provided");
  }

  const offset = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);

  return adjustedDate.toISOString().slice(0, -1);
}

export function formatCurrency(value) {
  value = parseInt(value, 10) / 100;
  return new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  }).format(value);
}

export function formatCurrencydes(value) {
  // value = parseInt(value, 10) / 100;
  return new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  }).format(value);
}

export function obtenerMesActual() {
  const meses = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", 
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  const fechaActual = new Date();
  const mesActual = fechaActual.getMonth(); // Esto devuelve un número entre 0 (para Enero) y 11 (para Diciembre)

  return meses[mesActual];
}

export const ordersGridDesmbolso = [
  {
    field:'Monto',
    headerText: 'Monto',
    textAlign: 'Center',
    width: '140',
  },
  { field: 'status',
    headerText: 'Estado',
    template: '',
    width: '150',
    textAlign: 'Center',
  },
  {
    field: 'CuentaBancaria',
    headerText: 'Cuenta Bancaria',
    format: 'C2',
    textAlign: 'Center',
    editType: 'numericedit',
    width: '150',
  },
  {
    headerText: 'Fecha de Desembolso',
    field: 'Fecha',
    textAlign: 'Center',
    width: '125',
  }
];
export function reorganizarfile(data) {
  const newData = data.map(item => ({
    'id de la transaccion': item.id,
    'fecha': item.finalized_at,
    'referencia': item.reference,
    'monto':item.amount_in_cents/100,
    'iva':0,
    'impuesto al consumo':0,
    'moneda':item.currency,
    'medio de pago':item.payment_method_type,
    'email del pagador':item.customer_email,
    'nombre del pagador':item.extra?.fullName || item.customer_data?.fullName,
    'telefono del pagador':item.extra?.cellphoneNumber||item.customer_data?.phone_number,
    'id conciliacion':'',
    'id link de pago':'',
    'documento del pagador':item.user_legal_id||item.customer_data?.legal_id,
    'tipo de documento del pagador':item.user_legal_id_type||item.customer_data?.legal_id_type,
  }));
  return newData; 
}
export default {formatDateTime,formatCurrency,obtenerMesActual,toLocalISOString,reorganizarfile}