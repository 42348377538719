import React, { useState, useEffect } from "react"; // Asegúrate de importar useEffect
import { Link, NavLink } from "react-router-dom";
// import { Tooltip } from "react-tooltip";
import { links } from "../data/data";
import { Divider,Sidebar } from 'keep-react';
import "../App.css";
import {
  SignIn
} from 'phosphor-react';
import { useNavigate } from "react-router-dom";

const Sidebarp = ({ activeMenu,changeoption, option }) => {
  const navigate = useNavigate();
  const empresa = localStorage.getItem("Empresa");
  const [currentColor, setCurrentColor] = useState("#03C9D7");

  const handleCloseSideBar = (option) => (e) => {
    changeoption(option);
  };

  const [activeLink, setactiveLink] = useState("flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2");
  const [normalLink, setnormalLink] = useState("flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md  dark:hover:text-black hover:bg-light-gray m-2");//text-gray-700 dark:text-gray-700

  const optionmenu = (menu) => {
    if (empresa !== "901662465" && menu === "Desembolsar") {
      return "none";
    }else if (empresa == "901662465" && menu === "Desembolsos") {
      return "none";
    }else {
      return "flex";
    }
  };
  const Logout = () => {
    //console.log("Logout");
    localStorage.removeItem("sesion");
    localStorage.removeItem("config");
    navigate("/");
  };
  return (
    <div className={`ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 ${activeMenu ? "shadow-xl" : ""}`}>
      {activeMenu && (
        <>
          {/* <div className="justify-between items-center">
            <Link to="/Layout" className="flex items-center mr-3 mt-4 text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
              <div className="flex flex-grow justify-center">
                <img className="w-20" src={require("../assets/img/Ipaid.png")} />
              </div>
            </Link>
            <Tooltip place="bottom" type="dark" effect="solid" />
          </div>
          <div className="mt-5 ">
            {links.map((item) => (
              <div key={item.title}>
                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                  {item.title}
                </p>
                {item.links.map((link) => (
                  <NavLink
                    key={link.name}
                    onClick={handleCloseSideBar(link.name)}
                    style={{ background: (option === link.name ? currentColor : ""), display: (optionmenu(link.name)) }}
                    className={option === link.name ? activeLink : normalLink}
                  >
                    {link.icon}
                    <span className="capitalize">{link.name}</span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div> */}
          
<Sidebar>
<Sidebar.Header className="space-y-2.5 justify-between items-center">
  {/* <div className="flex items-center justify-between"> */}
    <Link to="/Layout" className="flex items-center mr-3 mt-4 text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
    <div className="flex flex-grow justify-center">
      <img className="w-20" src={require("../assets/img/Ipaid.png")} />
    </div>
    </Link>
  {/* </div> */}
</Sidebar.Header>
<Sidebar.Body>
{links.map((item) => (
       <div key={item.title}>
       <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
         {item.title}
       </p>
       {item.links.map((link,index) => (
          <Sidebar.Item style={{ cursor:"pointer",background: (option === link.name ? currentColor : ""), display: (optionmenu(link.name)) }}
          key={index} onClick={handleCloseSideBar(link.name)}
          className={option === link.name ? activeLink : normalLink}>
          {link.icon}
          {/* {link.name} */}
          <span className="capitalize">{link.name}</span>
        </Sidebar.Item> 
                ))}
              </div>
    ))}
        <Sidebar.Item style={{ cursor:"pointer"}}  onClick={Logout}>
          <SignIn size={20} />
          <span className="capitalize">Salir</span>
        </Sidebar.Item>
</Sidebar.Body>
<Divider className="my-3" />
</Sidebar>
        </>
      )}
    </div>
  );
};
export default Sidebarp;
