import React from "react";
import "../assets/css/SkeletoNav.css";
const NavbarSkeleton = () => {
  return (
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">
      {/* Placeholder para el botón del Menú */}
      <div className=" h-8 w-8 rounded-full mr-4"></div>

      <div className="flex">
        {/* Placeholder para el perfil del usuario */}
        <div className="flex items-center gap-2">
          <div className="skeleton-avatar rounded-full w-8 h-8 mr-2"></div>
          <div className="skeleton h-4 w-20 rounded-full mr-4"></div>
          {/* <div className="skeleton h-4 w-8 rounded"></div> */}
        </div>
      </div>
    </div>
  );
};

export default NavbarSkeleton;
