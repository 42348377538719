import React from 'react';
import { MdOutlineCancel } from "react-icons/md";
import Button from './Button'; // Asegúrate de poner la ruta correcta
import avatar from '../data/avatar.jpg';
import { userProfileData } from "../data/data";
import { useNavigate } from "react-router-dom";
import "../assets/css/Profile.css";

const UserProfileDropdown = ({onClose,data,changeoption}) => {
const navigate = useNavigate();
  // console.log("no esta llegando nada")
  //  console.log("desde el componenete",data);
  // if(data){
  //   handleActiveNav();
  // }
  // Toda la lógica del component
  const [currentColor, setCurrentColor] = React.useState("#03C9D7");
  const Logout = () => {
    //console.log("Logout");
    localStorage.removeItem("sesion");
    localStorage.removeItem("config");
    navigate("/");
  };
  const handleClikc = () => {
    //console.log("click");
    // navigate("/Perfil");
    changeoption("perfil");
    onClose();
  };
  return (
    // <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
       <div className="nav-item absolute right-1 top-16 bg-white dark:bg-white p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-500">
          Perfil Usuario
        </p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
          control="0"
          onClick={onClose}
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={data.imagen ? data.imagen : avatar}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-500">
            {" "}
            {data.nombre ? data.nombre : "Usuario"}
          </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">
            {" "}
            Administrator{" "}
          </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {" "}
            {data.correo ? data.correo : "Usuario"}
          </p>
        </div>
      </div>
      <div>
        {userProfileData.map((item, index) => (
          <div
            key={index}
            className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
            // onClick={handleClick}
          >
            <button
              type="button"
              style={{ color: item.iconColor, backgroundColor: item.iconBg }}
              className=" text-xl rounded-lg p-3 hover:bg-light-gray"
              onClick={() => handleClikc()}
            >
              {item.icon}
            </button>

            <div>
              <p className="font-semibold dark:text-gray-500 ">{item.title}</p>
              <p className="text-gray-500 text-sm dark:text-gray-400">
                {" "}
                {item.desc}{" "}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5">
        <Button
          color="white"
          bgColor={currentColor}
          text="Salir"
          borderRadius="10px"
          width="full"
          control="1"
          onClick={Logout}
        />
      </div>
    </div>
  );
};
export default UserProfileDropdown;
