import React, { useEffect, useState } from "react";
import "../assets/css/Login.css";
import COVER_IMAGE from "../assets/img/COVER_IMG.png";
import IpaidLogo from "../assets/img/Ipaid.png";
import Loading from "../component/Loading";
import { Input, Label, Icon, Button } from "keep-react";
import { Envelope, EyeSlash, Eye, Lock } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import Footer from "../component/Footer";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  let nit;
  //estados
  const [showPassword, setShowPassword] = React.useState(false);
  const [form, setForm] = React.useState({ correo: "", password: "" });
  const [ErrorLogin, setErrorLogin] = React.useState(false);
  const [camposVacios, setCamposVacios] = React.useState(false);

  // funciones
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
    var input = document.getElementById("txtPassword");
    if (input.type === "password") {
      input.type = "text";
    } else {
      input.type = "password";
    }
  };

  async function getConfigEmpresa(nit) {
    try {
      //   console.log("nit:", nit);
      const response = await fetch(`${apiUrl}/getconfigempresa/`, {
        method: "GET", // Cambiado a GET
        headers: {
          Authorization: "Bearer " + nit,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }

      const data = await response.json();

      return data; // Devuelve los datos para ser usados en el componente
    } catch (error) {
      console.error("Error:", error);
      throw error; // Lanza el error para manejarlo en el componente
    }
  }

  async function getUsers(correo, password) {
    try {
      //   console.log("apiUrl:", apiUrl);
      const response = await fetch(
        `${apiUrl}/getUsuarios?email=${correo}&pss=${password}`,
        {
          method: "GET", // Cambiado a POST
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }

      const data = await response.json();
      nit = await data.nit;
      return data; // Devuelve los datos para ser usados en el componente
    } catch (error) {
      console.error("Error:", error);
      throw error; // Lanza el error para manejarlo en el componente
    }
  }
  const validateForm = (e) => {
    if (form.correo === "" || form.password === "") {
      //   alert("Los campos no pueden estar vacios");
      setCamposVacios(true);
      return false;
    }
    return true;
  };

  const handlechage = (e) => {
    // console.log(e.target.name, e.target.value);
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const singIn = (e) => {
    const res = validateForm();
    if (res) {
      setLoading(true); // Inicia el indicador de carga
      getUsers(form.correo, form.password)
        .then((data) => {
          const nit = data.nit; // Obtiene 'nit' de la respuesta de 'getUsers'
          localStorage.setItem("Empresa", nit);
          return getConfigEmpresa(nit); // Encadena la promesa para 'getConfigEmpresa'
        })
        .then((dataConfigEmpresa) => {
          localStorage.setItem("config", JSON.stringify(dataConfigEmpresa));
          localStorage.setItem("sesion", true);
          navigate("/Layout");
        })
        .catch((error) => {
          console.log(error);
          setErrorLogin(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setCamposVacios(false);
      setErrorLogin(false);
    }, 6000);
  }, [camposVacios || ErrorLogin]);

  return (
    <>
      {loading && <Loading />}
      <div className="mx-5 my-5 rounded-xl h-[560px] border border-gray-300 shadow-sm overflow-hidden ">
        <div className="bg-[#f5f5f5] w-full h-full flex items-start overflow-hidden">
          <div className="relative w-full  h-full  flex-col overflow-hidden hidden lg:block">
            <div className="border-r w-[100%] border-gray-300">
              <img
                src={COVER_IMAGE}
                alt="Descripción de la imagen"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="flex items-start justify-center mt-10 min-h-screen lg:w-2/3 mx-auto ">
            <div className="w-full max-w-md p-10 flex flex-col bg-white shadow-lg rounded-lg ">
              <img
                src={IpaidLogo}
                alt="logo image"
                className="w-[60px] mx-auto md:block lg:hidden mb-5"
              />
              <h1 className="font-extrabold text-center mb-2">Inicia sesión</h1>
              <h3 className="text-2xl mb-5 font-semibold text-center">
                Bienvenidos!
              </h3>

              <fieldset className="space-y-6">
                <div className="space-y-2">
                  <Label htmlFor="#id-9" value="Correo" />
                  <div className="relative">
                    <Input
                      onChange={handlechage}
                      id="#id-9"
                      name="correo"
                      color="gray"
                      sizing="md"
                      placeholder="example@gmail.com"
                      className="ps-11"
                      // autoComplete="off"
                    />
                    <Icon>
                      <Envelope size={20} color="#5E718D" />
                    </Icon>
                  </div>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="#id-10" value="Password" />
                  <div className="relative">
                    <Input
                      onChange={handlechage}
                      id="txtPassword"
                      name="password"
                      placeholder="**********"
                      color="gray"
                      sizing="md"
                      type={showPassword ? "text" : "password"}
                      className="ps-11"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          singIn();
                        }
                      }}
                    />
                    <Icon>
                      <Lock size={20} color="#5E718D" />
                    </Icon>
                    <button
                      onClick={handleShowPassword}
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    >
                      {showPassword ? (
                        <Eye size={20} color="#5E718D" />
                      ) : (
                        <EyeSlash size={20} color="#5E718D" />
                      )}
                    </button>
                  </div>
                </div>

                {camposVacios && (
                  <div
                    id="toast-bottom-right"
                    className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow"
                  >
                    <div className="flex-shrink-0 text-yellow-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3m0 3h.01m-7.778-3a9 9 0 1115.556 0M12 20.25a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5z"
                        />
                      </svg>
                    </div>
                    <div className="ml-4 text-sm font-normal">
                      Los campos no pueden estar vacios.
                    </div>
                  </div>
                )}
            {ErrorLogin && (
          <div
            id="toast-bottom-right"
            className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow"                      
            role="alert"
          >
            {/* Icono de Error */}
            <div className="flex-shrink-0 text-red-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>

            {/* Mensaje de Error */}
            <div className="text-sm font-normal ml-4">
              Usuario o contraseña incorrectos.
            </div>
          </div>
        )}
                <button
                  onClick={singIn}
                  className="w-full bg-black text-white rounded-md p-4 text-center"
                >
                  Login
                </button>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
