import React, { useState } from "react";
import "../assets/css/DataPicker.css"; // Cambia a la ubicación correcta de tu archivo CSS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faCheck, faEraser } from "@fortawesome/free-solid-svg-icons";
import FilterButton from "../component/transacciones_c/FilterButton";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const DatePickers = ({ update, filterTblTransaccion }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [reference, setReference] = useState("");
  const [state, setState] = useState("");
  const [email, setEmail] = useState("");
  // control de formulario filtro
  const [filter, setFilter] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const value = e.target.value;
    let idcliente = localStorage.getItem("Empresa");
    let config = localStorage.getItem("config");

    if (endDate && !startDate) {
      // alert("Por favor, introduce una fecha de inicio cuando especifiques una fecha de fin.");
      MySwal.fire(
        "Advertencia!",
        "introduce una fecha de inicio cuando especifiques una fecha de fin.",
        "warning"
      );
      return; // Previene el envío del formulario y finaliza la función aquí
    } else if (startDate && !endDate) {
      // alert("Por favor, introduce una fecha de fin cuando especifiques una fecha de inicio.");
      MySwal.fire(
        "Advertencia!",
        "introduce una fecha de fin cuando especifiques una fecha de inicio.",
        "warning"
      );
      return; // Previene el envío del formulario y finaliza la función aquí
    }

    setStartDate(startDate);
    setEndDate(endDate);
    // Comprobar si todos los campos están vacíos, excepto idcliente
    const isFormEmpty =
      !startDate &&
      !endDate &&
      !paymentMethod &&
      !reference &&
      !state &&
      !email;

    if (isFormEmpty) {
      // Mostrar un mensaje de alerta o manejar la situación de formulario vacío
      MySwal.fire(
        "Advertencia!",
        "Por favor, ingresa al menos un criterio de búsqueda adicional.",
        "warning"
      );
      return; // Previene la ejecución de la función
    }

    filterTblTransaccion(startDate, endDate);
  };
  const handleReset = () => {
    //settear correctamete el las fechas
    setStartDate("");
    setEndDate("");
    setPaymentMethod("");
    setReference("");
    setState("");
    setEmail("");
  };
  return (
    <form className="p-4 space-y-4 -mt-4 -mb-8 filter" onSubmit={handleSubmit}>
      {/* Primera fila: Entradas de fecha y botón de Actualizar */}
      <div className="flex justify-between items-center ml-1">
        <div className="content-end"></div>
        <div>
          <div className="flex justify-end space-x-2 mx-5">
            <FilterButton filter={filter} onToggle={() => setFilter(!filter)} />
            {/* <button
              type="button"
              onClick={update}
              className="bg-transparent transition-transform duration-500 ease-in-out transform hover:rotate-360 flex justify-center items-center"
              style={{ width: "40px", height: "40px" }}
            >
              <FontAwesomeIcon icon={faSync} />
            </button> */}
            <button
              type="button"
              onClick={update}
              className="bg-transparent flex justify-center items-center"
              style={{
                width: "40px",
                height: "40px",
                transition: "transform 0.5s ease-in-out",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "rotate(360deg)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "rotate(0deg)")
              }
            >
              <FontAwesomeIcon icon={faSync} />
            </button>
          </div>
        </div>
      </div>

      {/* Segunda fila: Botones de Filtrar y Descargar */}
      <div
        className={`transition-all duration-200 ease-in-out ${
          filter ? "max-h-[1000px]" : "max-h-0"
        } overflow-hidden`}
      >
        {/* Tercera y cuarta fila: Campos de selección y entrada */}
        <div className="mx-5">
          {/* Grilla con columnas que cambian según el tamaño de la pantalla */}
          <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-3">
            <div>
              <label
                htmlFor="startDate"
                className="text-start block text-sm font-medium text-gray-700"
              >
                Fecha de inicio
              </label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="cursor-pointer mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              />
            </div>
            <div>
              <label
                htmlFor="endDate"
                className="text-start block text-sm font-medium text-gray-700"
              >
                Fecha de fin
              </label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={endDate}
                min={startDate} // Establece la fecha mínima igual a la fecha de inicio
                max={new Date().toISOString().split("T")[0]} // Establece la fecha máxima igual a la fecha actual
                onChange={(e) => setEndDate(e.target.value)}
                className="cursor-pointer mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              />
            </div>
            <div className="col-span-full sm:col-span-2 lg:col-span-3 flex justify-end space-x-2">
              {/* SVG for Download icon */}
              <button
                type="submit"
                className="flex items-center justify-between px-4 py-2 border-2 border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none"
              >
                <FontAwesomeIcon icon={faCheck} className="mr-2" /> Aplicar
              </button>
              <button
                className="flex items-center justify-between px-4 py-2 border-2 border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none"
                type="button"
                onClick={handleReset}
              >
                <FontAwesomeIcon icon={faEraser} className="mr-2" /> Limpiar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DatePickers;
