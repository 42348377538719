import React, { useState, useEffect } from "react";
import { AiOutlineMenu, AiOutlineUser } from "react-icons/ai";
// import { MdOutlineCancel } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import UserProfile from "./UserProfile";
import { Tooltip } from "react-tooltip";
import SkeletonNav from "./SkeletonNav";
const Navbar = ({ handleActiveMenu,changeoption,option }) => {
  // const [screenSize, setScreenSize] = React.useState(null);
  const [currentColor, setCurrentColor] = React.useState("#03C9D7");
  const [showUserProfile, setShowUserProfile] = useState(false);
  // const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setShowUserProfile(!showUserProfile);
  };

  const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
    <>
      <button
        type="button"
        onClick={() => handleActiveMenu()}
        style={{ color }}
        className="relative text-xl rounded-full p-3 hover:bg-light-gray"
        data-tip={title}
        data-place="bottom"
      >
        <span
          style={{ background: dotColor }}
          className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
        />
        {icon}
      </button>
      <Tooltip />
    </>
  );
  useEffect(() => {
    //setIsLoading(true);
   // console.log("empresa", localStorage.getItem("Empresa"));
    //const nit = localStorage.getItem("Empresa");
      setData(JSON.parse(localStorage.getItem("config")));
      /*const fetchData = async () => {
        setIsLoading(true);
        try {
          const responsedata = await fetch(`${REACT_APP_API_URL}/getconfigempresa/${nit}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          });
          const res = await responsedata.json();
          setData(res);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
        finally {
          setIsLoading(false);
        }
      };
    
      fetchData();*/

    /*const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);*/
  }, []);

  /*useEffect(() => {
    if (screenSize <= 900) {
      // setActiveMenu(false);
    } else {
      // setActiveMenu(true);
    }
  }, [screenSize]);*/

  return (
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 z-50 relative">
      <NavButton title="Menu" color={currentColor} icon={<AiOutlineMenu />} />
      <span className="text-gray-400 font-bold text-15 mt-2.5 capitalize">
        {option}
      </span>
      <div>
        {isLoading ? (
          <SkeletonNav />
        ) : (
          <div className="flex">
            {showUserProfile && (
              <UserProfile
                data={data}
                onClose={() => setShowUserProfile(false)}
                changeoption={changeoption}
              />
            )}

            <div
              className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
              onClick={() => handleClick("userProfile")}
              data-tip="Profile"
            >
              {data.imagen ? (
                <img
                  id="imgperfil"
                  className="rounded-full w-8 h-8"
                  src={data.imagen}
                  alt="user-profile"
                />
              ) : (
                <AiOutlineUser />
              )}
              <p>
                <span className="text-gray-400 text-14">Hola,</span>
                {""}
                <span className="text-gray-400 font-bold ml-1 text-14">
                  {data.nombre ? data.nombre : "Usuario"}
                </span>
              </p>
              <MdKeyboardArrowDown
                className="text-gray-400 text-14"
                onClick={handleClick}
              />
            </div>

            <Tooltip place="bottom" type="dark" effect="solid" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
