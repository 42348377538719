import React ,{useState,useEffect } from 'react';
import Sidebarp from "./Sidebar";
import Navbar from "./Navbar";
import Resumen from "../Pages/Resumen";
import Transacciones from "../Pages/Transacciones";
import Perfil from "../Pages/Perfil";
import GenerateQR from "../Pages/GenerateQR";
import Desembolsar from "../Pages/Desembolsar";
import Desembolso from "../Pages/Desembolso";
import { Divider } from 'keep-react';

// const Layout = ({ children }) => {
  const Layout = () => {
  const [activeMenu, setActiveMenu] = useState(true);
  const [option, setoption] = useState('resumen');

  useEffect(() => {
    const handleResize = () => {
      // Activa o desactiva el sidebar basado en el tamaño de la pantalla
      setActiveMenu(window.innerWidth > 900);
      // console.log(window.innerWidth > 900);
    };

    // Agregar listener al montar el componente
    window.addEventListener('resize', handleResize);
    // Llamar al handler inmediatamente para establecer el estado inicial
    handleResize();

    // Limpiar el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleActiveMenu = () => {
    setActiveMenu(!activeMenu);
  };
  useEffect(() => {
    //console.log(activeMenu,11);
  }, []);
  const changeoption = (option) => {
    //  console.log(activeMenu);
    setoption(option);
    if (window.innerWidth < 900)
    setActiveMenu(false);
  };
  const [initialized, setInitialized] = useState({
    resumen: true,
    Transacciones: false,
    perfil: false,
    QR: false,
    Desembolsar:false,
    Desembolsos:false
  });
  const initializeComponent = (key) => {
    setInitialized(prev => ({ ...prev, [key]: true }));
  };
  useEffect(() => {
    initializeComponent(option);
  }, [option]);
  return (
    <div className="flex h-screen dark:bg-main-dark-bg bg-main-bg">
    {/* Sidebar */}
    <div className={`w-${activeMenu ? "85" : "0"} fixed dark:bg-secondary-dark-bg bg-white h-full`}>
      <Sidebarp activeMenu={activeMenu} changeoption={changeoption} option={option} />
    </div>

      {/* Main Content */}
      <div className={`flex-grow ${activeMenu ? "ml-64": "ml-0"} h-full`}>
        <Navbar handleActiveMenu={handleActiveMenu} changeoption={changeoption}  option={option}/>
        <Divider  size="lg" className="my-1" />
        <div className="mt-3">
          {initialized.resumen && (
        <div style={{ display: option === "resumen" ? "block" : "none" }}>
          <Resumen changeoption={changeoption} />
        </div>
      )}
       {initialized.Desembolsar && (
        <div style={{ display: option === "Desembolsar" ? "block" : "none" }}>
          <Desembolsar />
        </div>
      )}
       {initialized.Desembolsos && (
        <div style={{ display: option === "Desembolsos" ? "block" : "none" }}>
          <Desembolso/>
        </div>
      )}
      {initialized.Transacciones && (
        <div style={{ display: option === "Transacciones" ? "block" : "none" }}>
          <Transacciones />
        </div>
      )}
      {initialized.perfil && (
        <div style={{ display: option === "perfil" ? "block" : "none" }}>
        <Perfil />
        </div>
      )}
      {initialized.QR && (
        <div style={{ display: option === "QR" ? "block" : "none" }}>
               <GenerateQR />
        </div>
      )}
          </div>
      </div>
    </div>
  );
};

export default Layout;
