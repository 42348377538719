import React from "react";
// import "../assets/css/Profile.css";
import '../assets/css/Spinner.css';

const Loading = () => {
  return (
    // <div className="loader-container">
    //   <div className="loader"></div>
    // </div>
    <div className="spinner-overlay">
            <div className="spinner"></div>
        </div>
  );
};

export default Loading;
