import React, { useEffect, useState } from "react";
import { Accordion,Avatar,Button,Table,Spinner } from 'keep-react';
import { Money,DownloadSimple} from 'phosphor-react';
import excelIcon from '../assets/img/excel.svg';
import { formatCurrencydes, formatDateTime } from "../data/utilidades";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as XLSX from 'xlsx';
import "../assets/css/Desembolsar.css";

const MySwal = withReactContent(Swal);

const Desembolsar = () => {
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingfile, setIsLoadingfile] = useState(false);
    const [error, setError] = useState(null);
    const[data,setdata]=useState([]);
    const init = () => {
      fetch(
          `${REACT_APP_API_URL}/cliente/getclientesD`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
              setdata(data);
            // console.log("data 2", data.data);
          })
          .catch((error) => {
            setError(error.message);
          });
  };
    useEffect(() => {
        init();
      }, []); 
   const Desembolsar = (idc)=>(e) => {
      e.preventDefault();
      setIsLoading(true);
      fetch(
        `${REACT_APP_API_URL}/desembolso/Gdesembolso?idc=${idc}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          //  console.log(data);
           if(data.mensaje!==""){
            MySwal.fire("Desembolso", data.mensaje,"error");
           }else{
            init();
            MySwal.fire("Desembolso", "Generado correctamente","success");
           }
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error.message);
        });
    };
    const getdesinfo = (idc,namedes)=>(e) => {
      e.preventDefault();
      setIsLoadingfile(true);
      fetch(
        `${REACT_APP_API_URL}/desembolso/getdesinfo?idc=${idc}&namedes=${namedes}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          //  console.log(data);
          if(data!==null){
            const fileName=namedes;
            const workbook = XLSX.utils.book_new();
          
            const worksheet = XLSX.utils.json_to_sheet(data);
          
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          
            XLSX.writeFile(workbook, `${fileName}.xlsx`);
          }
          setIsLoadingfile(false);
        })
        .catch((error) => {
          setError(error.message);
        });
    };
    return (
        <div style={{padding:'8px'}}>           
    <Accordion className="space-y-4 accordion">
    {data.map((row, index) => (
      <Accordion.Panel className="panel" key={index}>
        <Accordion.Container>
        <Accordion.Title className="accordion-title" style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar img={row.imagen} />
        <span style={{ marginLeft: '8px',color:"#3d4a5c" }}>{row.nombre}</span>
        </Accordion.Title>
          <Accordion.Icon/>
        </Accordion.Container>
        <Accordion.Content className="accordion-content" style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ alignSelf: 'flex-end', marginBottom: '10px' }}>
        <Button color="secondary" variant="outline"  onClick={Desembolsar(row.idcliente)}>
         { isLoading? <span className="pr-2">
          <Spinner color="gray" size="md" />
        </span>:<Money size={20} className="mr-1.5" />}
           Generar Desembolso   
        </Button> 
      </div>
      <Table showCheckbox={false}>
      <Table.Caption>
        <div className="my-5 flex items-center justify-between px-6">
          <div className="flex items-center gap-5">
            <p className="text-body-1 font-semibold text-metal-600">Ultimos Desembolsos</p>
          </div>
          {isLoadingfile && <Spinner color="gray" size="lg" />}
        </div>
      </Table.Caption>
      <Table.Head className="thead">
        <Table.HeadCell className="min-w-[210px]">
          <p className="text-body-5 font-medium text-metal-400">Nombre</p>
        </Table.HeadCell>
        <Table.HeadCell className="min-w-[140px]">Total</Table.HeadCell>
        <Table.HeadCell className="min-w-[151px]">Fecha</Table.HeadCell>
        <Table.HeadCell className="min-w-[151px]">Nro cuenta</Table.HeadCell>
        {/* <Table.HeadCell className="min-w-[150px]">Last uploaded</Table.HeadCell> */}
        <Table.HeadCell className="min-w-[80px]" />
      </Table.Head>
      <Table.Body className="divide-gray-25 divide-y">
      {row.dese.map((rowdes, index) => (
        <Table.Row className="bg-white" key={index}>
          <Table.Cell>
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <Avatar shape="circle" img={excelIcon} size="md" />
                  <div>
                    <p className="-mb-0.5 text-body-4 font-medium text-metal-600">{rowdes.namedes+".xlsx"}</p>
                    {/* <p className="text-body-5 font-normal text-metal-500">200 KB</p> */}
                  </div>
                </div>
              </div>
            </div>
          </Table.Cell>
          <Table.Cell >
            <p className="text-body-5 font-medium text-metal-500">{formatCurrencydes(rowdes.Monto)}</p>
            {/* <p className="text-body-5 font-normal text-metal-500">200 KB</p> */}
          </Table.Cell>
          <Table.Cell >
            <p className="text-body-5 font-medium text-metal-500">{formatDateTime(rowdes.Fecha)}</p>
            {/* <p className="text-body-5 font-normal text-metal-500">4:45 pm</p> */}
          </Table.Cell>
          <Table.Cell >
            <p className="text-body-5 font-medium text-metal-500">{rowdes.CuentaBancaria}</p>
          </Table.Cell>
          <Table.Cell >
            <Button disabled={isLoadingfile} color="secondary"  variant="outline" size="sm" shape="circle" onClick={getdesinfo(rowdes.idcliente,rowdes.namedes)}>
              <DownloadSimple  size={15} />
            </Button>
          </Table.Cell>
        </Table.Row>
         ))}
      </Table.Body>
    </Table>
        </Accordion.Content>
      </Accordion.Panel>
    ))}
    </Accordion>
        </div>
      );
};

export default Desembolsar;
