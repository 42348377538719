import React, { useEffect } from "react";
import "./App.css";
// import Login from "./component/Login";
import Login from "./Pages/Login";
import Layout from "./component/Layout";
import { ContextProvider } from "./context/ContextProvider";
import { HashRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(true);
  useEffect(() => {
    //console.log(23455);
    const token = localStorage.getItem("token");
    if (token) {
      // setIsLoggedIn(true);
    }
  }, []);
  return (
<ContextProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        {/* <Route
          path="/resumen"
          element={
            <Layout>
              <Resumen />
            </Layout>
          }
        />
          <Route
          path="/Transacciones"
          element={
            <Layout>
              <Transacciones />
            </Layout>
          }
        />
          <Route
          path="/perfil"
          element={
            <Layout>
              <Perfil/>
            </Layout>
          }
        />
           <Route
          path="/Desembolsos"
          element={
            <Layout>
              <Desembolso/>
            </Layout>
          }
        />
             <Route
          path="/QR"
          element={
            <Layout>
              <GenerateQR/>
            </Layout>
          }
        /> */}
        <Route
          path="/Layout"
          element={
            <Layout>
              {/* <Resumen /> */}
            </Layout>
          }
        />
      </Routes>
    </Router>
    </ContextProvider>
  );
}

export default App;
